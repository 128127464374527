<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            冰山數據Partner
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          请等待审批! 😊
        </b-card-title>
        <b-card-text class="mb-2">
          您已经成功上传了合同，我们会尽快完成审批。
        </b-card-text>


        <b-card-text class="mb-2">
          同时，我们也推荐您关注我们的<a style="color:#7367f0 !important" @click="toWebsite">官方网站</a>，以及我们的微信公众号，以便及时获取我们的最新动态。
        </b-card-text>

        <div style="overflow:hidden" class="mb-2">
          <img style="height: 100%; width: 100%;" :src="qrCodeSrc">
        </div>
        
        <div class="divider my-2">
          <div class="divider-text">
          </div>
        </div>

        <b-button
          variant="outline-danger"
          block
          @click="signOut"
        >
              退出登录
          </b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInvalidFeedback,BFormTextarea,BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,BFormFile
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'


export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      fileDownloading:false,
      isSaving:false,
      companyData:{},
      abilityOptions:[
        { value:'image',text:'图像标注'},
        { value:'audio',text:'音频标注'},
        { value:'text',text:'文本标注'},
        { value:'pointcloud',text:'点云标注'},
        { value:'collection',text:'数据采集'},
      ],
      qrCodeSrc: require('/public/QRCODE.png'),
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,

      companyName: '',
      companyCity: '',
      contactName: '',
      contactPhone: '',
      labelers: '',
      abilities: [],
      introFile: null,
      introFileName: '',
      introFileFsId: '',
    }
  },
  methods:{
    toWebsite(){
      window.open('https://www.markhk.cn', '_blank')
    },
    signOut(){
      localStorage.removeItem('userData')
      localStorage.removeItem('jwt')
      this.$router.push({ name: 'auth-login-partner' })
    },
    saveInfo(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.isSaving = true
          var formData = new FormData()
          if (this.introFile) formData.append('introduction',this.introFile)
          if (!this.introFile) this.introFile = null
          formData.append('company_name',this.companyName)
          formData.append('company_city',this.companyCity)
          formData.append('contact_name',this.contactName)
          formData.append('contact_phone',this.contactPhone)
          formData.append('labelers',this.labelers)
          formData.append('abilities',this.abilities)
          this.$axios.post('/partner/companies/update_info',formData).then((response)=>{
            this.isSaving = false
        if (response.data.status == "ok"){
          this.getInfo()
        }
      }
      )
      }})
    },
    getInfo(){
      this.$axios.get('/partner/companies/get_info').then(resp =>{
        var info = resp.data.data.info
        this.companyName = info.company_name
        this.companyCity = info.company_city
        this.contactName = info.contact_name
        this.contactPhone = info.contact_phone
        this.labelers = info.labelers
        this.abilities = info.abilities
        this.introFileName = info.intro_file_name
        this.introFileFsId = info.intro_file_fs_id
        this.$refs.refFormObserver.reset()
      })
    },
    downloadIntro(){
      this.fileDownloading = true
      this.$axios.get("/partner/companies/download_intro/" + this.introFileFsId).then(res=>{  
        this.fileDownloading = false
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', this.companyData.intro_file_name)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }      
      })
    },
    
  },
  created(){
    this.getInfo()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    introPlaceholder(){
      if (this.introFileName) {
        return this.introFileName
      } else {
        return '请选择或拖拽文件文档至此（仅支持pdf格式）...'
      }
    }
  },
  setup() {
    const {getValidationState} = formValidation()
    return {
      getValidationState,
}
},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
